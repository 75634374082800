import { BookRender } from "@atoms/book";
import { Button } from "@atoms/button/button";
import { InputCounter } from "@atoms/input/input-counter";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, BaseBold, BaseSmall, Info, InfoSmall, Title } from "@atoms/text";
import {
  CheckCircleIcon,
  TagIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { Table } from "@molecules/table";
import { atom, useRecoilState, useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import {
  ReceptionLine,
  ReceptionUnit,
  RelatedOrder,
} from "@features/reception/types";
import { useReception } from "@features/reception/state/use-reception";
import { toast } from "react-hot-toast";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useEffect, useState } from "react";
import { SelectedItemReception } from "@features/reception/state/store";
import { ProductsApiClient } from "@features/products/api-client/api-client";
import { focusInput } from "@features/utils/divers";
import { PiWarningBold } from "react-icons/pi";

type AffectationModalAtomType = {
  open: boolean;
  receipt: ReceptionUnit | null;
  item: ReceptionLine | null;
  orders: RelatedOrder[];
  queingItem?: ReceptionLine | null;
  total?: number;
  automaticMode?: boolean;
  itemList?: ReceptionLine[];
  autoPrint?: boolean;
};
export const AffectationCmdClientModalAtom = atom<AffectationModalAtomType>({
  key: "AffectationCmdClientModalAtom",
  default: {
    open: false,
    receipt: {} as ReceptionUnit | null,
    item: {} as ReceptionLine | null,
    orders: [] as RelatedOrder[],
    total: 0,
    automaticMode: false,
    itemList: [] as ReceptionLine[],
    autoPrint: true,
  },
});

export const AffectationCmdClientModal = () => {
  const [affectationCmdClientModal, setAffectationCmdClientModal] =
    useRecoilState(AffectationCmdClientModalAtom);
  const [ordersList, setOrdersList] = useState<RelatedOrder[]>([]);
  const [printLoading, setPrintLoading] = useState(false);
  const [stockQte, setStockQte] = useState(0);
  const {
    loading,
    selectedReception,
    getReceipt,
    changeSelectedReception,
    updateLine,
    printLabels,
    receiptLines,
    getRelatedOrders,
  } = useReception();
  const setSelectedItem = useSetRecoilState(SelectedItemReception);

  const { t } = useTranslation();

  useControlledEffect(() => {
    if (affectationCmdClientModal.orders)
      setOrdersList(
        affectationCmdClientModal.orders
          .filter((el) => el.numCdeCli !== "")
          .map((el, i) => {
            return { ...el, htmlId: `affecinput-${i}` };
          })
      );
  }, [affectationCmdClientModal.open, affectationCmdClientModal.item]);

  useEffect(() => {
    if (affectationCmdClientModal.open) {
      setTimeout(() => {
        focusInput("#affecinput-0");
      }, 250);
    } else {
    }
  }, [affectationCmdClientModal.open]);

  useControlledEffect(() => {
    const stockInfos = affectationCmdClientModal.orders.find(
      (el) => el.numCdeCli === ""
    );
    if (stockInfos) setStockQte(stockInfos.qteProposee);
  }, [affectationCmdClientModal]);

  useControlledEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Tab") {
        const rank = parseInt(document.activeElement?.id.split("-")[1] || "-1");
        if (rank < 0 || rank >= ordersList.length) {
          focusInput("#affecinput-0");
        } else focusInput(`#affecinput-${rank + 1}`);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Modal
      className="sm:max-w-[900px]"
      loading={loading || printLoading}
      open={affectationCmdClientModal.open}
      onClose={() => {
        const activEl = document.activeElement;
        (activEl as HTMLInputElement).blur();
        setAffectationCmdClientModal({
          ...affectationCmdClientModal,
          open: false,
          automaticMode: false,
        });
      }}
    >
      <ModalContent title={"Affectation client"}>
        <div className="grow flex flex-col gap-2">
          {stockQte < 0 && (
            <div className="w-full flex flex-row gap-2 justify-center items-center">
              <PiWarningBold className="w-5 h-5 text-red-400" />
              <BaseBold>
                Veuillez rectifier les affectations afin que la quantité en
                stock ne soit plus négative.
              </BaseBold>
            </div>
          )}
          <Frame className="flex gap-2">
            <div className="flex flex-col w-full justify-between ">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row items-center space-x-4 ">
                  <div className="w-10 print:hidden">
                    <BookRender
                      src={
                        affectationCmdClientModal.item?.imageURL &&
                        affectationCmdClientModal.item.imageURL
                      }
                      productType={
                        (affectationCmdClientModal.receipt &&
                          affectationCmdClientModal.receipt.modeGest) ||
                        "P"
                      }
                    />
                  </div>

                  <div className="flex flex-col my-2">
                    <Base
                      className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
                      data-tooltip={
                        affectationCmdClientModal.item?.desig &&
                        affectationCmdClientModal.item.desig
                      }
                    >
                      {affectationCmdClientModal.item?.desig &&
                        affectationCmdClientModal.item.desig}
                    </Base>
                    <Info>
                      {affectationCmdClientModal.item?.desig &&
                        affectationCmdClientModal.item.eaN13}
                    </Info>
                  </div>
                </div>
                <div className="h-full flex items-center gap-8">
                  <Title>{`${
                    affectationCmdClientModal.orders.find(
                      (el) => el.numCdeCli === ""
                    )?.qteProposee
                  } ${t("reception.versStock")}`}</Title>
                  <div className="h-full flex flex-col gap-2 border-2 p-2 justify-center items-center">
                    <InfoSmall>Étiqueter</InfoSmall>

                    <Button
                      className="w-full"
                      size="sm"
                      theme="secondary"
                      disabled={loading}
                      icon={({ className }) => (
                        <TagIcon className={className} />
                      )}
                      onClick={async () => {
                        try {
                          setPrintLoading(true);
                          if (affectationCmdClientModal.item)
                            await ProductsApiClient.productLabel([
                              {
                                eaN13: affectationCmdClientModal.item.eaN13,
                                nbrEtiq:
                                  affectationCmdClientModal.orders[0].nbrEtiq,
                              },
                            ]);
                          setPrintLoading(false);
                        } catch {
                          setPrintLoading(false);
                        }
                      }}
                    />
                    <div
                      id="stock-counter"
                      style={{ maxWidth: 96, minWidth: 96 }}
                    >
                      <InputCounter
                        disabled={selectedReception.trait}
                        size="sm"
                        value={
                          stockQte < 0
                            ? 0
                            : affectationCmdClientModal.orders.find(
                                (el) => el.numCdeCli === ""
                              )
                            ? affectationCmdClientModal.orders.find(
                                (el) => el.numCdeCli === ""
                              )!.nbrEtiq
                            : 0
                        }
                        onChange={(newValue) => {
                          if (stockQte >= 0)
                            setAffectationCmdClientModal(
                              (previous: AffectationModalAtomType) => {
                                const lineIndex = previous.orders.findIndex(
                                  (l: RelatedOrder) => l.numCdeCli === ""
                                );
                                if (lineIndex !== -1) {
                                  let newOrders = [...previous.orders];
                                  newOrders[lineIndex] = {
                                    ...newOrders[lineIndex],
                                    nbrEtiq: newValue,
                                  };

                                  const stockOrder = {
                                    ...previous.orders.find(
                                      (el: RelatedOrder) => el.numCdeCli === ""
                                    ),
                                    qteProposee:
                                      previous.item!.qteRecu -
                                      newOrders.reduce(
                                        (sum, el) => sum + el.qteProposee,
                                        0
                                      ),
                                    nbrEtiq:
                                      previous.item!.qteRecu -
                                      newOrders.reduce(
                                        (sum, el) => sum + el.qteProposee,
                                        0
                                      ),
                                  };
                                  if (
                                    stockOrder !== undefined ||
                                    stockOrder !== null
                                  ) {
                                    return {
                                      ...previous,
                                      orders: [...newOrders],
                                    };
                                  } else {
                                    return { ...previous };
                                  }
                                }
                                return { ...previous };
                              }
                            );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Frame>
          <Table
            className="mt-2"
            emptyTabText="Aucune commande client associée à cet article"
            columns={[
              {
                title: t("reception.quantityToAffect"),
                render: (order) => (
                  <div style={{ maxWidth: 120, minWidth: 120 }}>
                    <InputCounter
                      disabled={selectedReception.trait}
                      id={order.htmlId}
                      size="md"
                      max={order.qteRest}
                      min={0}
                      value={order.qteProposee}
                      onChange={(newValue) => {
                        let lineIndex = -1;
                        let newOrders: RelatedOrder[] = [];
                        let stockOrder: RelatedOrder;
                        setAffectationCmdClientModal(
                          (previous: AffectationModalAtomType) => {
                            // Mettre à jour la liste des commandes
                            lineIndex = previous.orders
                              .filter((el) => el.numCdeCli !== "")
                              .findIndex(
                                (l) => l.numCdeCli === order.numCdeCli
                              );

                            if (lineIndex !== -1) {
                              newOrders = [
                                ...previous.orders.filter(
                                  (el) => el.numCdeCli !== ""
                                ),
                              ];
                              const tempOrder = { ...newOrders[lineIndex] };
                              tempOrder.qteProposee = newValue;
                              tempOrder.nbrEtiq = newValue;
                              newOrders[lineIndex] = tempOrder;

                              stockOrder = {
                                ...previous.orders.find(
                                  (el: RelatedOrder) => el.numCdeCli === ""
                                ),
                              } as RelatedOrder;

                              stockOrder.qteProposee =
                                affectationCmdClientModal.item!.qteRecu -
                                newOrders.reduce(
                                  (sum, el) => sum + el.qteProposee,
                                  0
                                );
                              stockOrder.nbrEtiq =
                                affectationCmdClientModal.item!.qteRecu -
                                newOrders.reduce(
                                  (sum, el) => sum + el.qteProposee,
                                  0
                                );

                              if (stockQte >= 0 && stockOrder.qteProposee < 0) {
                                toast(
                                  "La quantité vers stock ne peut être négative"
                                );
                                return { ...previous };
                              }
                              return {
                                ...previous,
                                orders: [{ ...stockOrder }, ...newOrders],
                              };
                            } else return { ...previous };
                          }
                        );
                      }}
                    />
                  </div>
                ),
              },
              {
                title: t("reception.infosCommandes"),
                render: (order) => (
                  <div className="flex flex-col">
                    <BaseSmall>Num cde: {order.numCdeCli}</BaseSmall>
                    <BaseSmall>
                      {order.qteCde} cdé cli {order.codeCanalVente}
                    </BaseSmall>
                  </div>
                ),
              },
              {
                title: t("reception.Client"),
                orderable: true,
                render: (order) => (
                  <div className="flex flex-col gap-2">
                    <Base>{order.nomClient}</Base>
                    <InfoSmall>
                      {order.mailCli} {order.telCli}
                    </InfoSmall>
                  </div>
                ),
              },

              {
                title: "Étiqueter",
                render: (order, i) => (
                  <div className="flex gap-2">
                    <Button
                      className="shrink-0"
                      size="sm"
                      theme="secondary"
                      disabled={loading || order.linePk === ""}
                      onClick={() => {
                        if (affectationCmdClientModal.item)
                          printLabels(
                            undefined,
                            affectationCmdClientModal.item,
                            order.nbrEtiq,
                            order.linePk.split("|")[0]
                          );
                      }}
                      icon={({ className }) => (
                        <TagIcon className={className} />
                      )}
                    />
                    <div
                      id="stock-counter"
                      style={{ maxWidth: 96, minWidth: 96 }}
                    >
                      <InputCounter
                        disabled={selectedReception.trait}
                        size="sm"
                        value={order.nbrEtiq}
                        min={0}
                        onChange={(newValue) => {
                          let lineIndex = -1;
                          let newOrders: RelatedOrder[] = [];
                          //let stockOrder: RelatedOrder;
                          setAffectationCmdClientModal(
                            (previous: AffectationModalAtomType) => {
                              lineIndex = previous.orders.findIndex(
                                (l) => l.numCdeCli === order.numCdeCli
                              );
                              if (lineIndex !== -1) {
                                newOrders = [...previous.orders];
                                const tempOrder = { ...newOrders[lineIndex] };
                                tempOrder.nbrEtiq = newValue;
                                newOrders[lineIndex] = tempOrder;

                                return {
                                  ...previous,
                                  orders: [...newOrders],
                                };
                              } else {
                                return previous;
                              }
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                ),
              },
            ]}
            data={affectationCmdClientModal.orders.filter(
              (l) => l.numCdeCli !== ""
            )}
          />
          <div className="w-full flex justify-center gap-8 my-4">
            <Button
              disabled={loading}
              theme="danger"
              icon={(p) => <XCircleIcon {...p} />}
              onClick={() => {
                setAffectationCmdClientModal({
                  ...affectationCmdClientModal,
                  open: false,
                  automaticMode: false,
                });
              }}
            >
              {selectedReception.trait ? "Fermer" : t("cancel")}
            </Button>
            <Button
              shortcut={["enter"]}
              disabled={loading || selectedReception.trait || stockQte < 0}
              theme="primary"
              icon={(p) => <CheckCircleIcon {...p} />}
              onClick={async () => {
                if (
                  affectationCmdClientModal.item &&
                  affectationCmdClientModal.receipt
                ) {
                  const res = await updateLine(
                    {
                      ...affectationCmdClientModal.item,
                      autoLabel:
                        affectationCmdClientModal.receipt &&
                        affectationCmdClientModal.receipt.etiqCdeCli !== 2 &&
                        (affectationCmdClientModal.autoPrint || false) &&
                        !affectationCmdClientModal.automaticMode,
                    },
                    affectationCmdClientModal.orders.map((r) => {
                      return { ...r, mailCli: r.mailCli ? r.mailCli : "" };
                    }),
                    true
                  );
                  if (!res) {
                    toast.error("Impossible d'affecter les lignes !");
                  } else {
                    toast.success("Lignes affectée avec succès !");
                    if (affectationCmdClientModal.queingItem) {
                      const index = receiptLines.items.findIndex(
                        (el) =>
                          el.linePk ===
                          affectationCmdClientModal.queingItem?.linePk
                      );
                      setSelectedItem({
                        ...affectationCmdClientModal.queingItem,
                        qteRecu:
                          index !== -1 ? receiptLines.items[index].qteRecu : 1,
                      });
                    } else {
                      setSelectedItem(null);
                    }
                    if (
                      affectationCmdClientModal.automaticMode &&
                      affectationCmdClientModal.itemList &&
                      affectationCmdClientModal.itemList.length > 1
                    ) {
                      const tempTab = [
                        ...affectationCmdClientModal.itemList,
                      ].slice(1);
                      if (tempTab[0] && affectationCmdClientModal.receipt) {
                        const nextItemOrders = await getRelatedOrders(
                          affectationCmdClientModal.receipt,
                          tempTab[0].eaN13,
                          tempTab[0].linePk,
                          tempTab[0].qteRecu,
                          true
                        );
                        setAffectationCmdClientModal({
                          ...affectationCmdClientModal,
                          item: tempTab[0],
                          orders: nextItemOrders,
                          itemList: tempTab,
                        });
                      }
                    } else {
                      const updatedReception = await getReceipt(
                        selectedReception
                      );
                      if (affectationCmdClientModal.automaticMode)
                        printLabels(
                          updatedReception,
                          undefined,
                          undefined,
                          undefined,
                          true
                        );

                      changeSelectedReception(updatedReception);
                      setAffectationCmdClientModal({
                        receipt: null,
                        item: null,
                        orders: [],
                        open: false,
                        queingItem: null,
                      });
                    }
                  }
                }
              }}
            >
              {t("confirm")}
            </Button>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
