import { Modal, ModalContent } from "@atoms/modal/modal";
import { dataLibrairie } from "@views/client/dashboard/fakeData";
import { atom, useRecoilState } from "recoil";
import {
  LinearScale,
  CategoryScale,
  Chart as ChartJS,
  BarElement,
  BarController,
  Title,
  Tooltip,
  ArcElement,
  Legend,
  LineController,
  LineElement,
  PointElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Button } from "@atoms/button/button";
import { CheckIcon } from "@heroicons/react/outline";
import { Frame } from "@atoms/layout/frame";
import { BaseBold } from "@atoms/text";
import { ReassortProductLine } from "@features/supplier/types";
import { GraphTab } from "@components/product/productGraphTab";
import { InputCounter } from "@atoms/input/input-counter";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import toast from "react-hot-toast";
import { Input } from "@atoms/input/input-text";
import { useAuth } from "@features/general/auth/state/use-auth";
import { useState } from "react";
import { focusInput } from "@features/utils/divers";
import { PageLoader } from "@atoms/layout/page-loader";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  ArcElement,
  LineElement,
  LineController,
  PointElement,
  ChartDataLabels,
  Title,
  Tooltip,
  Legend
);

type ProductGraphModalType = {
  open: boolean;
  product?: ReassortProductLine;
  modeGest?: string;
  codeTypeProd?: string;
};

export const chartData = {
  labels: dataLibrairie.map((el) => el.mois),
  datasets: [
    {
      type: "line" as const,
      label: "Ventes",
      borderColor: "#06b6d4",
      borderWidth: 3,
      fill: false,
      data: dataLibrairie.map((el) => el.revenus),
    },
  ],
};

export const ProductGraphModalAtom = atom<ProductGraphModalType>({
  key: "ProductGraphModalAtom",
  default: { open: false, product: undefined },
});

export const ProductGraphModal = () => {
  const [productGraphModal, setProductGraphModal] = useRecoilState(
    ProductGraphModalAtom
  );
  const [waitRank, setWaitRank] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const { user } = useAuth();

  const {
    loading,
    reassortLines,
    updateReassortLine,
    setReassortLines,
    getReassortLines,
    setReassortLines: changeReassortLines,
  } = useSuppliers();

  const handleClose = () => {
    setProductGraphModal({ open: false, product: undefined });
  };

  useControlledEffect(() => {
    if (productGraphModal.open) {
      const index = reassortLines.items.findIndex(
        (l) => l.eaN13 === productGraphModal.product?.eaN13
      );
      if (index !== -1) setWaitRank(index);
      setPageNumber(reassortLines.pagination.pageNumber);
    } else {
      setWaitRank(0);
      setPageNumber(1);
    }
    setTimeout(() => {
      if (productGraphModal.open && productGraphModal.product?.eaN13) {
        focusInput("#qte-counter");
      }
    }, 250);
  }, [productGraphModal.open, productGraphModal.product?.eaN13]);

  return (
    <Modal
      className="sm:!max-w-[1300px]"
      open={productGraphModal.open}
      onClose={() => handleClose()}
    >
      <ModalContent
        title={`Informations sur: ${
          productGraphModal.modeGest === "L"
            ? productGraphModal.product?.titre
            : productGraphModal.product?.designation
        }`}
      >
        {loading ? (
          <PageLoader />
        ) : (
          <div className="flex flex-col gap-1 md:gap-4">
            <Button
              onClick={async () => {
                const newProduct = reassortLines.items[waitRank + 1];
                if (newProduct) {
                  setProductGraphModal({
                    ...productGraphModal,
                    product: newProduct,
                  });
                  setWaitRank((previous) => previous + 1);
                  return;
                } else {
                  if (reassortLines.pagination.totalRows > 0) {
                    const res = await getReassortLines(
                      productGraphModal.modeGest || "",
                      productGraphModal.codeTypeProd || "",
                      {
                        pageNumber: pageNumber + 1,
                        pageSize: 10,
                        orderBy: "date_creat", // À modifier
                        orderDir: "DESC",
                      }
                    );
                    if (res) {
                      changeReassortLines(res);
                      setProductGraphModal({
                        ...productGraphModal,
                        product: res.items[0],
                      });
                      setPageNumber((previous) => previous + 1);
                      setWaitRank(0);
                    }
                  } else {
                    handleClose();
                  }
                }
              }}
              className="self-end"
            >
              Attendre
            </Button>
            {productGraphModal.product && (
              <Frame className="w-full h-full flex flex-row justify-between items-center">
                <GraphTab product={productGraphModal.product} type="bar" />
              </Frame>
            )}
            <div className="w-full flex gap-2 justify-between">
              <div className="flex gap-2">
                <BaseBold className="flex flex-col justify-center">
                  Quantité:
                </BaseBold>
                <div className="h-full flex flex-col justify-center max-w-44">
                  <InputCounter
                    id="qte-counter"
                    size={"sm"}
                    value={
                      productGraphModal.product
                        ? productGraphModal.product.qte
                        : 0
                    }
                    min={0}
                    onChange={(newValue) => {
                      if (productGraphModal.product) {
                        const reasLine = { ...productGraphModal.product };
                        reasLine.qte = newValue;
                        setProductGraphModal({
                          ...productGraphModal,
                          product: reasLine,
                        });
                      }
                    }}
                  />
                </div>
              </div>

              {/* <div className="flex gap-2 ">
              <BaseBold className="flex flex-col justify-center">
                Choix:
              </BaseBold>

              <div className="h-full flex flex-col justify-center">
                <Select
                  size="sm"
                  className="max-w-44"
                  onChange={(event) => {
                    if (productGraphModal.product) {
                      const reasLine = { ...productGraphModal.product };
                      reasLine.decis = event.target.value;
                      setProductGraphModal({
                        ...productGraphModal,
                        product: reasLine,
                      });
                    }
                  }}
                >
                  <option value="-">-</option>
                  <option value="ATT">Attendre</option>
                  <option value="CDE">Commander</option>
                  <option value="ABD">Abandonner</option>
                </Select>
              </div>
            </div> */}
              {productGraphModal.product &&
                productGraphModal.product.qte > 0 && (
                  <div className="flex gap-2">
                    <BaseBold className="flex flex-col justify-center">
                      Panier:
                    </BaseBold>
                    <div className="h-full flex flex-col justify-center">
                      <Input
                        onChange={(e) =>
                          setProductGraphModal({
                            ...productGraphModal,
                            product: productGraphModal.product
                              ? {
                                  ...productGraphModal.product,
                                  panie: e.target.value,
                                }
                              : undefined,
                          })
                        }
                        size="sm"
                        placeholder="ex: panier..."
                      />
                    </div>
                  </div>
                )}
              <div className="flex gap-2">
                <Button
                  theme={
                    productGraphModal.product &&
                    productGraphModal.product.qte > 0
                      ? "valid"
                      : "danger"
                  }
                  data-tooltip="Valider choix"
                  shortcut={["enter"]}
                  onClick={async () => {
                    if (productGraphModal.product) {
                      const res = await updateReassortLine(
                        productGraphModal.product.id,
                        productGraphModal.product.eaN13,
                        productGraphModal.product.qte,
                        productGraphModal.product.panie,
                        productGraphModal.product.tooltip.codeEtat,
                        user?.id || ""
                      );
                      if (res) {
                        toast.success("Ligne mise à jour avec succès");
                        const updatedLines = await getReassortLines(
                          productGraphModal.modeGest || "",
                          productGraphModal.codeTypeProd || "",
                          {
                            pageNumber: pageNumber,
                            pageSize: 10,
                            orderBy: "date_creat", // À modifier
                            orderDir: "DESC",
                          }
                        );
                        if (updatedLines) {
                          setReassortLines(updatedLines);
                          if (updatedLines.items.length > 0)
                            setProductGraphModal({
                              ...productGraphModal,
                              product: updatedLines.items[0],
                            });
                          else handleClose();
                        }
                      } else {
                        toast.error(
                          "Erreur lors de la mise à jour de la ligne"
                        );
                      }
                    }
                  }}
                  icon={({ className }) => <CheckIcon className={className} />}
                >
                  {productGraphModal.product &&
                  productGraphModal.product.qte > 0
                    ? "Valider"
                    : "Abandon"}
                </Button>
                {/* <Button
                theme="danger"
                data-tooltip="Annuler choix"
                onClick={() => handleClose()}
                icon={({ className }) => <XIcon className={className} />}
              >
                Annuler
              </Button> */}
              </div>
            </div>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};
