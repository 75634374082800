import {
  ArchiveIcon,
  BookOpenIcon,
  ChartBarIcon,
  ClipboardListIcon,
  DatabaseIcon,
  ShoppingCartIcon,
  TruckIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { ROUTES } from "./routes";
import Env from "@config/environment";
import { LiaReplyAllSolid, LiaShippingFastSolid } from "react-icons/lia";
import { MdOutlineWarehouse } from "react-icons/md";

export type ModuleType = {
  name: string;
  subTitle?: string;
  route: (typeof ROUTES)[keyof typeof ROUTES];
  logo: React.ReactNode;
  color?: string;
  disabled?: boolean;
  hideModule?: boolean;
  useFrom?: boolean;
};

export const modules: ModuleType[] = [
  {
    name: "Clients",
    subTitle: "Historique des commandes clients",
    logo: <UserCircleIcon className="w-10 h-10 text-blue-500" />,
    route: ROUTES.Clients,
  },
  {
    name: "Produits",
    subTitle: "Rechercher des produits et passer des commandes",
    logo: <ShoppingCartIcon className="w-10 h-10 text-blue-500" />,
    route: ROUTES.Products,
  },
  {
    name: "Réassort",
    subTitle: "Gestion du réassort",
    logo: <TruckIcon className="w-10 h-10 text-blue-500" />,
    route: ROUTES.FournReassort,
    color: Env.isBeta ? "grey" : "",
    disabled: Env.isBeta,

  },
  {
    name: "Réceptions",
    subTitle: "Création et historique des réceptions",
    logo: <ArchiveIcon className="w-10 h-10 text-blue-500" />,
    route: ROUTES.Receptions,
  },
  {
    name: "Retours",
    subTitle: "Gestion des retours fournisseurs",
    logo: <LiaReplyAllSolid className="w-10 h-10 text-blue-500" />,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
    disabled: Env.isBeta,
  },
  {
    name: "Presse BL/BI",
    subTitle: "Module Presse/BL",
    logo: <BookOpenIcon className="w-10 h-10 text-blue-500" />,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
    disabled: Env.isBeta,
  },
  {
    name: "Inventaire",
    subTitle: "Module inventaire",
    logo: <DatabaseIcon className="w-10 h-10 text-blue-500" />,
    route: ROUTES.Unavailable,
    color: Env.isBeta ? "grey" : "",
    disabled: Env.isBeta,
  },
  {
    name: "Tableau de bord",
    subTitle: "Visualisation des stocks, ventes...",
    logo: <ChartBarIcon className="w-10 h-10 text-blue-500" />,
    route: Env.isBeta ? ROUTES.Unavailable : ROUTES.Dashboard,
    color: Env.isBeta ? "grey" : "",
    disabled: Env.isBeta,
  },

  {
    name: "Cde fournisseur",
    subTitle: "Encours et historique des commandes fournisseur",
    logo: <MdOutlineWarehouse className="w-10 h-10 text-blue-500" />,
    route: Env.isBeta ? ROUTES.Unavailable : ROUTES.SupplierOrderPage,
    color: Env.isBeta ? "grey" : "",
    disabled: Env.isBeta,
  },

  {
    name: "Préparation cde fourn",
    subTitle: "Visualisation des commandes en préparation",
    logo: <ClipboardListIcon className="w-10 h-10 text-blue-500" />,
    route: Env.isBeta ? ROUTES.Unavailable : ROUTES.SupplierPrepOrderPage,
    color: Env.isBeta ? "grey" : "",
    disabled: Env.isBeta,
  },
  {
    name: "Envoi cde",
    subTitle: "Envoie de commande à partir des commandes en préparation",
    logo: <LiaShippingFastSolid className="w-10 h-10 text-blue-500" />,
    route: Env.isBeta ? ROUTES.Unavailable : ROUTES.SupplierSendOrderPage,
    color: Env.isBeta ? "grey" : "",
    disabled: Env.isBeta,
  },

  {
    name: "Préparation commande fournisseur",
    logo: <ChartBarIcon className="w-10 h-10 text-blue-500" />,
    route: ROUTES.SupplierCommandOne,
    hideModule: true,
    useFrom: true,
  },

  {
    name: "Préparation retour fournisseur",
    logo: <ChartBarIcon className="w-10 h-10 text-blue-500" />,
    route: ROUTES.SupplierRetourOne,
    hideModule: true,
    useFrom: true,
  },
];
