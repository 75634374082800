import InputDate from "@atoms/input/input-date";
import { Base } from "@atoms/text";
import { Column } from "@molecules/table/table";

const LIVR_REAS_COLUMNS = [
  {
    title: "Auteur",
    id: "auteu",
    orderable: true,
    render: (p) => <Base>{p.auteur}</Base>,
  },
  {
    title: "Auteur-Compositeur",
    id: "auteurCompositeur",
    orderable: true,
    render: (p) => <Base>{p.auteurCompositeur}</Base>,
  },
  {
    title: "Éditeur",
    id: "code_edit",
    orderable: true,
    render: (p) => <Base>{p.code_edit}</Base>,
  },
  {
    title: "Fournisseur",
    id: "code_fourn",
    orderable: true,
    render: (p) => <Base>{p.codeFournHabit}</Base>,
  },
  {
    title: "Fabricant",
    id: "codeFab",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Famille",
    id: "lib_famil",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Sous-famille",
    id: "lib_sous_famil",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Marque",
    id: "code_marque",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Vendeur",
    id: "logi",
    orderable: true,
    render: (p) => <Base>{p.logi}</Base>,
  },
  {
    title: "Promo",
    id: "prod_lieu_canal_vente_code_promo",
    orderable: true,
    render: (p) => <Base>{p.codePromo}</Base>,
  },
  {
    title: "Rayon",
    id: "libRayon",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Departement",
    id: "codeDepartement",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Marge Nette",
    id: "marge_nette_nette",
    orderable: true,
    render: (p) => <Base>{p.marge_nette_nette}</Base>,
  },
  {
    title: "Couv.",
    orderable: true,
    render: (p) => <Base>{p.delaiStock}</Base>,
  },
  {
    title: "Stock",
    orderable: true,
    id: "qte_stock",
    render: (p) => <Base>{p.qte_stock}</Base>,
  },
  {
    title: "Vente",
    orderable: true,
    render: (p) => <Base>{p.vente}</Base>,
  },
  {
    title: "Vente jour",
    id: "qte_vente_jour",
    orderable: true,
    render: (p) => <Base>{p.qte_vente_jour}</Base>,
  },
  {
    title: "Mouvement",
    id: "lib_type_mvt",
    orderable: true,
    render: (p) => <Base>{p.lib_type_mvt}</Base>,
  },
  {
    title: "Prix Vente",
    id: "prix_vente_HT",
    orderable: true,
    render: (p) => <Base>{p.prix_vente_HT}</Base>,
  },
  {
    title: "Première entrée",
    id: "date_prem_entre",
    orderable: true,
    render: (p) => (
      <InputDate size={"sm"} disabled={true} value={p.date_prem_entre} />
    ),
  },
  {
    title: "Éditeur",
    id: "code_edit",
    orderable: true,
    render: (p) => <Base>{p.code_edit}</Base>,
  },
  {
    title: "Diffuseur",
    id: "code_diffu",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "CodeColl",
    id: "jsp",
    orderable: true,
    render: (p) => <Base>{null}</Base>,
  },
  {
    title: "Vendeur",
    id: "logi",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Poche",
    id: "poche",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },

  {
    title: "Total",
    orderable: true,
    render: (p) => <Base>{1}</Base>,
  },
  {
    title: "Date parution",
    id: "date_paru",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "État",
    id: "code_etat",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Occasion",
    id: "occas",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Support",
    id: "lib_supp",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
  {
    title: "Genre",
    id: "lib_genre",
    orderable: true,
    render: (p) => <Base>{p.libRayon}</Base>,
  },
] as Column<any>[];

export const getReassortTabColumns = (modeGestion: string) => {
  switch (modeGestion) {
    case "L":
      return LIVR_REAS_COLUMNS;
    case "D":
      return LIVR_REAS_COLUMNS;
    case "P":
      return LIVR_REAS_COLUMNS;
    default:
      return LIVR_REAS_COLUMNS;
  }
};
