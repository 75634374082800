import { AuthJWT } from "@features/general/auth/jwt";
import { nanoid } from "nanoid";

export const setLocalStorage = (
  type: "session" | "user",
  key: string,
  value: any
) => {
  const prefixedKey = localStorageBuildPrefixKey(type, key);

  localStorage.setItem(prefixedKey, JSON.stringify(value));
};

export const localStorageBuildPrefixKey = (
  type: "session" | "user",
  key: string
) =>
  type === "user"
    ? `user-${AuthJWT.userId}-${key}`
    : `session-${AuthJWT.userId}-${key}-${window.sessionStorage.getItem(
        "sessionID"
      )}`;

export const createSession = () => {
  const sessionID = nanoid();
  window.sessionStorage.setItem("sessionID", sessionID);
};

export const getLocalStorage = (type: "user" | "session", key: string) => {
  const prefixedKey = localStorageBuildPrefixKey(type, key);
  //console.log(prefixedKey);
  const jsonValue = localStorage.getItem(prefixedKey);
  return jsonValue != null ? JSON.parse(jsonValue) : null;
};

export const clearSessionStorage = () => {
  for (const key in localStorage) {
    const prefixedKey = localStorageBuildPrefixKey("session", "");
    if (key.startsWith(prefixedKey) || key.startsWith("tab-sync-")) {
      localStorage.removeItem(key);
    }
  }
};

export const clearUserStorage = (id?: string) => {
  for (const key in localStorage) {
    if (key.startsWith("user-" + id)) {
      localStorage.removeItem(key);
    }
  }
};

(window as any).clearUserStorage = clearUserStorage;
(window as any).clearSessionStorage = clearSessionStorage;
